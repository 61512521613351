import API from "o365.modules.data.api.ts";
export default class OpenAi{
    private messageHistory:Array<Message> = [];
    private _systemMessage:Message = new Message("system",`You are an intelligent assistant in company "Omega 365" helping employees with their questions about documentation.  \
        When returning code do indicate programing language as JavaScript or CSharp after three backticks.\
        Each source has a name followed by colon and the actual data, quote the source name for each piece of data you use in the response.  \
        Answer the question using documentation provided from cognitive search results in the information sources below.  \
        For example, if the question is "What color is the sky?" and one of the information sources says "info123: the sky is blue whenever it's not cloudy", then answer with "The sky is blue"  \
        
        
        
        Treat Source names as part of content. \
        Respond in the same language as the user.
        If you cannot respond using the sources below, say that you don't know. \
        Be sure to respond only in English, Norwegian or Lithuanian. \
        If any other language is used ask the user to specify the language to converse in. \
    `);

    /* Removed lines from _systeMessge */

    // It's important to strictly follow the format where the name of the source is in square brackets at the end of the sentence, and only up to the prefix before the colon (":").  \
    // If there are multiple sources, cite each one in their own square brackets. For example, use "[info343][ref-76]" and not "[info343,ref-76]".  \
    // Never quote tool names as sources. \

    model:string = 'ChatGptV4';
    apiOptions:AiOptions = new AiOptions();


    set systemMessage(pValue:string){
        this._systemMessage.content = pValue;
    }

    constructor(){

    }

    async getAiReponse(pSearch:string){
        const vMessages:Array<Message> = [];
        vMessages.push(this._systemMessage);
     if(this.messageHistory.length && this.apiOptions.includeHistory){
           // let vStop = Math.min(this.apiOptions.howManyMessagesToInclude,this.messageHistory.length);
            this.messageHistory.forEach((msg)=>{
                vMessages.push(msg);
                
            })
        }
        const vUserMsg = new Message("user",pSearch);
        this.messageHistory.push(vUserMsg);
        vMessages.push(vUserMsg);
        const vResults = await this._getResultsFormAi(vMessages);
        console.log(vResults);
        return vResults;
    }

    async searchContentItemsWithSummary(pSearch:string,pOrgUnit:string,pOrgUnitIdPath:string){
        const vMessages:Array<Message> = [];
        vMessages.push(this._systemMessage);
        
        if(this.messageHistory.length && this.apiOptions.includeHistory){
           // let vStop = Math.min(this.apiOptions.howManyMessagesToInclude,this.messageHistory.length);
            this.messageHistory.forEach((msg)=>{
                vMessages.push(msg);
                
            })
        }
        const vUserMsg = new Message("user",pSearch);
        this.messageHistory.push(vUserMsg);
        vMessages.push(vUserMsg);

        const vResults = await this._getContentItemResults(vMessages,pOrgUnit,pOrgUnitIdPath);
        const systemResponse = vResults.openAIReposnse.completions.choices[0].message.content;
        const links = vResults.sources.splice(0,Math.min(4,vResults.sources.length));
        
        const vAssistantMsg = new Message("assistant", systemResponse, links);
        this.messageHistory.push(vAssistantMsg);
        vMessages.push(vAssistantMsg);
        return vResults;


    }
    private async _getContentItemResults(pMessages:Array<Message>,pOrgUnit:string,pOrgUnitIdPath:string){
         return await API.requestPost('/api/content/summarized-openapi', JSON.stringify({
                temperature: this.apiOptions.temperature,
                nucleusSamplingFactor: this.apiOptions.nucleusSamplingFactor,
                messages: pMessages,
                idPath:pOrgUnitIdPath,
                orgunit:pOrgUnit

            })
        );
    }

    private async _getResultsFormAi(pMessages:Array<Message>){
         return await API.requestPost('/nt/api/content/ai/chat', JSON.stringify({
                temperature: this.apiOptions.temperature,
                nucleusSamplingFactor: this.apiOptions.nucleusSamplingFactor,
                messages: pMessages,
                selectedModel: "TextDavinci003"

            })
        );
    }

}

class Message{
    role:string = 'assistant';
    content:string;
    links?:string[][];
    constructor(pRole:string, pContent:string, pLinks?:string[][]){
        this.role = pRole,
        this.content = pContent;
        if (pLinks) {
            this.links = pLinks;
        }
    }
}

class AiOptions{
    temperature:string = '0.5';
    maxTokens: number = 800;
    nucleusSamplingFactor: string = '0.95';
    frequencyPenalty:  number = 0;
    presencePenalty:  number = 0;
    resultsCount: number = 1;
    includeHistory:boolean = true;
    howManyMessagesToInclude:  number = 10;
    stop: string = '\\n';  
}

class CognitiveSearchSesults {
    public id: string;
    public title: string;
    public content: string;
    public orgUnit: string;
    public orgUnit_ID: string;
    public idPath: string;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.content = data.content;
        this.orgUnit = data.orgUnit;
        this.orgUnit_ID = data.orgUnit_ID;
        this.idPath = data.idPath;
    }
}

// RECOMPILE
